import React, { useState } from "react";
import Box from "@mui/material/Box";
import {
  List,
  ListItem,
  Typography,
  Button,
  TextareaAutosize,
} from "@mui/material";
import axios from "axios";
import { Container } from "@mui/system";
import FeedbackIcon from "@mui/icons-material/Feedback";
import SendIcon from "@mui/icons-material/Send";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const FeedbackMentor = props => {
  const { nothing } = props;
  const pathArray = nothing.split("/");
  const room_id = pathArray[2];

  const [feedback, setFeedback] = useState("");
  const [action_items, setAction_items] = useState("");

  const handleSubmit = e => {
    e.preventDefault();
    const finalJson = { room_id, feedback, action_items };
    axios
      .post(
        "https://heroes-dot-bosscoderplatform.uc.r.appspot.com/mentors/write_student_feedback",
        finalJson
      )
      .then(res => {
        console.log(res?.data?.success);
        if (res?.data?.success) {
          toast.success("Your response is recorded", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(res?.data?.error, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };

  return (
    <>
      <Box>
        <ToastContainer />
        <Container sx={{ mt: 4, mr: 4, mb: 2 }}>
          <List sx={{ ml: -5 }}>
            <ListItem>
              <FeedbackIcon fontSize="medium" style={{ color: "white" }} />
              <Typography
                sx={{
                  display: "inline-flex",
                  ml: 1,
                  fontSize: "24px",
                  color: "white",
                }}
              >
                Feedback
              </Typography>
            </ListItem>
          </List>
        </Container>
        <Box sx={{ pr: 2 }}>
          <div>
            <Typography
              variant="subtitle2"
              gutterBottom
              component="div"
              sx={{ color: "white" }}
            >
              Feedback for Mentee
            </Typography>

            <TextareaAutosize
              name="feedback to mentee"
              value={feedback}
              onChange={e => setFeedback(e.target.value)}
              style={{ width: "100%" }}
              minRows={8}
            />
            <Typography
              variant="subtitle2"
              gutterBottom
              component="div"
              sx={{ color: "white", mt: 1 }}
            >
              Milestones for Mentee
            </Typography>
            <TextareaAutosize
              name="how_course_work_is_ongoing"
              value={action_items}
              onChange={e => setAction_items(e.target.value)}
              style={{ width: "100%" }}
              minRows={8}
            />
          </div>

          <Box sx={{ mt: 5 }}>
            <Button
              sx={{
                float: "right ",
              }}
              variant="contained"
              endIcon={<SendIcon />}
              onClick={handleSubmit}
            >
              Send
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default FeedbackMentor;
